import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Illustration from '../../static/images/illustrations/doodle-illustration.png';
import cityLify from '../../static/images/illustrations/cityLify.png';
import footer from '../../static/images/illustrations/Footer.png';
import questionImg from '../../static/images/illustrations/Question.png';

const Home = props => {

  const data = props.data;

  return (
    <div>
      <Layout bodyClass="page-home">
        <SEO title={"Gratis quizer"} />
        <Helmet>
          <meta
            name="description"
            content="Bruk denne nettsiden til finne gratis quizer fra mange ulike kategorier. "
          />
        </Helmet>
        <div className="w-100 pt-1">
          <div className="d-flex justify-content-between align-content-center mb-4">
            <div className="py-5">
              <h2>Quizer i alle sjangre for alle aldre. </h2>
              <p>Test dine kunnskaper. Finn spørsmål i alle sjangre. Quiz med venner.</p>
            </div>
            <div className="d-none d-md-block">
              <div className="mx-4 d-flex">
                <img src={questionImg} style={{height: "215px", zIndex: "-3"}} className="splash"/>
              </div>
            </div>
          </div>
          <ul className="auto-grid">
            {data.allQuizlistJson.edges.map(({ node }) => (
              <Link to={node.link} className={`card w-100 ${node?.category}`} style={{width: "18rem"}}>
                <div className="card-body">
                  <h4 className="card-title bold h4">{node.name}</h4>
                  <div className="card-text q-text-sm">{node.beskrivelse}</div>
                </div>
              </Link>
            ))}
          </ul>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query IndexQuery {
    allQuizlistJson {
      edges {
        node {
          name
          beskrivelse
          category
          link
        }
      }
    }
  }
`;

export default Home;
